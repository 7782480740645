import { Box, Button, CardMedia, Divider, Grid, List, ListItem, Typography, useMediaQuery } from "@mui/material";
import useWindowDimensions from "../../Objects/windowDimensions";
import YoutubeItem from "../../Objects/YoutubeItem";

import { useLocation } from "react-router-dom";
import { useLayoutEffect, useState } from "react";


/**Landing page
 * Summarises me and the page */
export const Home = () => {    
    return (
        <Box justifyContent={"center"} alignItems={"center"} height={"100vh"} sx={{display:'flex'}}>
            <Grid container xs={12} justifyContent="center" alignItems="center" height={useWindowDimensions()} 
            sx={{position: 'relative', backgroundColor:"#fcf0e3"}}>
                <Grid container xs={12} justifyContent="center" alignItems="center" height={"30%"} sx={{backgroundColor:"#fcf0e3"}}>
                    <CardMedia 
                    component="img"
                    image="PolylopInkscape.png"
                    alt="Polylop Games Logo"
                    sx={{objectFit:"contain", maxHeight:"80%"}}/>
                </Grid>
                <Grid container xs={12} justifyContent="center" alignItems="center" height={"40%"} minHeight={300} sx={{backgroundColor:"#fcf0e3"}}>
                    <Box height={300} width={400}>
                        <YoutubeItem videoID={"_L4qauTiCY4"}/>
                    </Box>  
                </Grid>
                <Grid container xs={12} justifyContent="center" alignItems="center" height={"30%"} sx={{backgroundColor:"#fcf0e3"}}>
                    <Typography fontFamily={"Poppins"} fontSize={18}>Coming Soon!</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};