import { createHashRouter, RouterProvider} from "react-router-dom";
//Components
import { Home } from './Components/Home/Home';
//MUI
import ResponsiveAppBar from './Components/Generic/Navbar';
import { ThemeProvider } from '@mui/material/styles';

import Contact from "./Components/Contact/Contact";
import snesTheme from "./Components/Generic/Theme";
import { CssBaseline } from "@mui/material";

function App() {
    const router = createHashRouter([
      { path: '/', element: <Home/>}, 
      { path: '/contact', element: <Contact/>}, 
    ]);
    return (
      <ThemeProvider theme={snesTheme}>
        <CssBaseline>
          <div className="App">
            <ResponsiveAppBar />
            <RouterProvider router={router}/> 
          </div>
        </CssBaseline>
      </ThemeProvider>
    );
}

export default App;